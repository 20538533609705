import React, { Component } from 'react'
import CacheBuster from './CacheBuster'
import Navigation from './components/navigation';
import Header from './components/header';
import About from './components/about';
import Services from './components/services';
import Meta from './components/meta';
import Contact from './components/contact';
import JsonData from './data/data.json';
import Pricing from "./components/pricing";

/*import Features from './components/features';
import Gallery from './components/gallery';
import Testimonials from './components/testimonials';
import Team from './components/Team';*/

export class App extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    const metaObj = {
        title: "Weboosh Technology",
        description: "Weboosh is a company in information technology, especially software development. Weboosh services its client by utilizing advanced information technology. We target all markets such as government instances and companies, regardless of their business scales, which need the use of information technology.",
        keywords: "Weboosh, Weboosh corp, Weboosh technology, Weboosh teknologi"
    }

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return(
            <div>
              <Meta meta={metaObj} />
              <Navigation />
              <Header data={this.state.landingPageData.Header} />
              {/*<Features data={this.state.landingPageData.Features} />*/}
              <About data={this.state.landingPageData.About} />
              <Services data={this.state.landingPageData.Services} data2={this.state.landingPageData.Service}/>
              {/*<Gallery />*/}
              {/*<Testimonials data={this.state.landingPageData.Testimonials} />*/}
              {/*<Team data={this.state.landingPageData.Team} />*/}
              <Pricing data={this.state.landingPageData.Pricing} />
              <Contact data={this.state.landingPageData.Contact} />
            </div>
          )
        }}
      </CacheBuster>
    )
  }
}

export default App;
