import React from "react";
import {Helmet} from "react-helmet";

class Meta extends React.Component {
    render () {
        return (
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.meta.title}</title>

                    {/* NORMAL */}
                    <meta name="name" content={this.props.meta.title} />
                    <meta name="description" content={this.props.meta.description} />
                    <meta name="keywords" content={this.props.meta.keywords} />
                    <meta name="image" content={"img/logo.png"} />

                    {/* GOOGLE */}
                    <meta itemProp="name" content={this.props.meta.title} />
                    <meta itemProp="description" content={this.props.meta.description} />
                    <meta itemProp="image" content={"img/logo.png"} />

                    {/*FACEBOOK*/}
                    <meta property="og:url" content={"https://webooshcorp.com/"} />
                    <meta property="og:type" content={"website"} />
                    <meta property="og:title" content={this.props.meta.title} />
                    <meta property="og:description" content={this.props.meta.description} />
                    <meta property="og:image" content={"img/logo.png"} />

                    {/*TWITTER*/}
                    <meta name="twitter:card" content={"summary_large_image"} />
                    <meta name="twitter:title" content={this.props.meta.title} />
                    <meta name="twitter:description" content={this.props.meta.description} />
                    <meta name="twitter:image" content={"img/logo.png"} />

                    <link rel="canonical" href="https://webooshcorp.com/" />
                </Helmet>
                ...
            </div>
        );
    }
};

export default Meta;