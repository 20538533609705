import React, { Component } from "react";

export class Pricing extends Component {
    render() {
        let { data } = this.props;
        const features = [
            "Free Domain for One Year",
            "Best-in-class hosting",
            "Remove WordPress.com Ads",
            "Collect payments",
            "Unlimited Email Support",
            "Earn ad revenue",
            "Upload videos",
            "Google analytics integration",
            "Install plugins and extend functionality for your site with" +
            " access to more than 50,000 plugins",
            "Advanced SEO (Search Engine Optimization) tools",
            "Automated site backups and one-click restore",
            "SFTP (SSH File Transfer Protocol) and Database Access",
            "Accept payments in 60+ countries",
            "Integrations with Top Shipping Carriers",
            "Premium design options customized for online stores",
        ]

        return (
            <div id="pricing">
                <div className="container">
                    <div className="section-title text-center">
                        <h2>{data?.title}</h2>
                        <p>{data?.description}</p>
                    </div>
                    <div className="product-wrapper row">
                        {
                            data?.product.map((item, i) => {
                                return (<div key={i} className="col-xs-12 col-md-3 item">
                                    <div className={"item-top " + item.type} />
                                    <div className="title-wrapper">
                                        <h3>{item.name}</h3>
                                        <p>{item.description}</p>
                                        {
                                            item.isPopular ? <div className="popular">POPULAR</div> : null
                                        }
                                    </div>
                                    <h3>{item.price}</h3>
                                    <p className="price-description">per month, billed yearly</p>
                                    <button>
                                        <a href="https://wa.me/6289628576801" target="_blank">
                                            Start with {item.name}
                                        </a>
                                    </button>
                                    {
                                        features.map((feature, j) => {
                                            return <div key={j} className="feature-wrapper">
                                                <p>
                                                    {
                                                        (j+1) <= item.feature
                                                            ? <span><i className="fa fa-check"></i></span>
                                                            : <span><i className="fa fa-times"></i></span>
                                                    }
                                                    {" " + feature}
                                                </p>
                                            </div>
                                        })
                                    }
                                </div>)
                            })
                        }
                    </div>

                    <div className="d-flex">
                        <div className="special-order">
                            <h3>{data?.company.title}</h3>
                            <p>{data?.company.buttonText} <a href="https://wa.me/6289628576801" target="_blank">{data?.company.buttonText2}</a></p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Pricing;